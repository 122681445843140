import { Form, Input, Button, Alert, Select } from 'antd';
import { FC } from 'react';
import FormMaestro from './FormMaestro';

type Props = {
  data: any[];
  fields: any[];
  maestro: any[];
  isComplete: boolean;
  onContinue?: (values: any[]) => void;
  onComplete?: (values: any[]) => void;
  onBack?: () => void;
  loading?: boolean;
  index: number;
};

const FormGenerator: FC<Props> = ({
  onComplete,
  onContinue,
  onBack,
  data,
  loading,
  fields,
  maestro,
  isComplete,
  index,
}) => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    const response: any = [];

    for (const id_campo of Object.keys(values)) {
      const fieldData = fields.find((d) => d.id_campo === id_campo);

      if (fieldData) {
        response.push({
          id_campo,
          id: fieldData.id,
          value: values[id_campo],
        });
      }
    }
    if (isComplete) {
      onComplete?.(response);
    } else {
      onContinue?.(response);
    }
  };

  const renderFormFields = () => {
    return data.map((field) => {
      const { id_campo, glosa, tipo, obligatorio, alternativas, valor } = field;

      if (tipo === 'email') {
        return (
          <Form.Item
            key={id_campo}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
              {
                pattern:
                  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                message: `Por favor ingresar un ${glosa} válido.`,
              },
            ]}
          >
            <Input type={'text'} />
          </Form.Item>
        );
      }

      if (tipo === 'rut') {
        return (
          <Form.Item
            key={id_campo}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
              {
                pattern: /^\d{1,8}-[\dkK]$/,
                message: `Por favor ingresar un ${glosa} válido.`,
              },
            ]}
          >
            <Input type={'text'} />
          </Form.Item>
        );
      }

      if (tipo === 'maestro') {
        const dataPrice = field?.campos_por_accion?.data?.find(
          (d: any) => d.tipo === 'precio'
        );

        return (
          <Form.Item
            key={id_campo}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
            ]}
          >
            <FormMaestro
              form={form}
              maestro={maestro}
              name={id_campo}
              priceVisible={dataPrice?.visibilidad ?? false}
            />
          </Form.Item>
        );
      }

      if (tipo === 'texto' || tipo === 'numero') {
        return (
          <Form.Item
            key={id_campo + glosa}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
            ]}
          >
            <Input type={tipo === 'numero' ? 'number' : 'text'} />
          </Form.Item>
        );
      }

      if (tipo === 'opcion' || tipo === 'opcion_filtro') {
        const options = alternativas.split('|').map((option: string) => {
          return { value: option, label: option };
        });
        return (
          <Form.Item
            key={id_campo + alternativas}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
            ]}
          >
            <Select
              showSearch={tipo === 'opcion_filtro'}
              options={options}
              placeholder="Por favor seleccione"
            />
          </Form.Item>
        );
      }

      if (tipo === 'fecha') {
        return (
          <Form.Item
            key={id_campo}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
        );
      }

      if (tipo === 'fecha_hora') {
        return (
          <Form.Item
            key={id_campo}
            label={glosa}
            name={id_campo}
            rules={[
              {
                required: !!obligatorio,
                message: `Por favor ingresar ${glosa.toLowerCase()}.`,
              },
            ]}
          >
            <Input type="datetime-local" />
          </Form.Item>
        );
      }

      if (tipo === 'informacion') {
        return (
          <Form.Item key={id_campo}>
            <Alert type="info" showIcon description={valor} message={glosa} />
          </Form.Item>
        );
      }
      return null;
    });
  };

  return (
    <Form
      layout="vertical"
      form={form}
      name={`form_${index}`}
      onFinish={onFinish}
      style={{ width: '100%' }}
    >
      {renderFormFields()}
      <Form.Item
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {index !== 0 && (
          <Button
            type="default"
            htmlType="button"
            style={{ marginRight: '10px' }}
            onClick={() => onBack?.()}
          >
            Atrás
          </Button>
        )}
        <Button type="primary" htmlType="submit" loading={loading}>
          {isComplete ? 'Finalizar' : 'Continuar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default FormGenerator;
