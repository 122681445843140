import { FC, useEffect, useState } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Col,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Table,
} from 'antd';

type Props = {
  maestro: any[];
  form: FormInstance;
  name: string;
  priceVisible: boolean;
};

type ChipAdded = {
  id_chip: number;
  name: string;
  price: number;
  quantity: number;
};

type ChipSelected = {
  id_chip: number;
  iccid: string;
  name: string;
  price: number;
};

const FormMaestro: FC<Props> = ({ form, maestro, name, priceVisible }) => {
  const [chipsAdded, setChipsAdded] = useState<ChipAdded[]>([]);
  const [quantity, setQuantity] = useState<number>(1);
  const [chipSelected, setChipSelected] = useState<ChipSelected | null>(null);
  const [selectedValue, setSelectedValue] = useState('');
  const [columns, setColumns] = useState<any[]>([]);

  const handleSelect = (value: any, option: any) => {
    const chip = maestro.find((chip) => chip.id_chip === option.value);
    if (chip) {
      setChipSelected({
        id_chip: chip.id_chip,
        iccid: chip.iccid,
        name: chip.nombre,
        price: chip.precio,
      });
      setSelectedValue(chip.nombre);
    }
  };

  const handleAdd = () => {
    if (!chipSelected) return alert('Por favor selecciona un producto');
    if (!quantity) return alert('Por favor ingresa la cantidad');

    const chipAdded = chipsAdded.find(
      (chip) => chip.id_chip === chipSelected.id_chip
    );
    if (chipAdded) {
      setChipsAdded((prevChipsAdded) =>
        prevChipsAdded.map((ca) =>
          ca.id_chip === chipSelected.id_chip
            ? { ...chipSelected, quantity }
            : ca
        )
      );
    } else {
      setChipsAdded([...chipsAdded, { ...chipSelected, quantity }]);
    }

    /* form.setFieldsValue({
      [`chip_${chipSelected.id_chip}`]: chipSelected.name,
    } */

    setChipSelected(null);
    setSelectedValue('');
    setQuantity(1);
  };

  const handleRemoveRow = (id_chip: number) => {
    setChipsAdded([...chipsAdded].filter((ca) => ca.id_chip !== id_chip));
  };

  const handleEditQuantityRow = (id_chip: number, quantity: number) => {
    setChipsAdded((prevChipsAdded) =>
      prevChipsAdded.map((ca) =>
        ca.id_chip === id_chip ? { ...ca, quantity } : ca
      )
    );
  };

  useEffect(() => {
    if (chipsAdded.length === 0) {
      form.setFieldValue(name, null);
    } else {
      form.setFieldValue(name, chipsAdded);
    }
  }, [chipsAdded, form, name]);

  useEffect(() => {
    if (priceVisible) {
      setColumns([
        {
          key: 'name',
          title: 'Producto',
          dataIndex: 'name',
          render: (_: any, record: any) => {
            return record.name;
          },
        },
        {
          key: 'price',
          title: 'Precio',
          dataIndex: 'price',
          render: (_: any, record: any) => {
            return record.price;
          },
        },
        {
          key: 'quantity',
          title: 'Cantidad',
          dataIndex: 'quantity',
          render: (_: any, record: any) => {
            return (
              <InputNumber
                min={1}
                value={record.quantity}
                onChange={(value) =>
                  handleEditQuantityRow(record.id_chip, value ?? 1)
                }
              />
            );
          },
        },
        {
          key: 'actions',
          title: 'Acciones',
          render: (_: any, record: any) => {
            return (
              <Button
                type="primary"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveRow(record.id_chip)}
              />
            );
          },
        },
      ]);
    } else {
      setColumns([
        {
          key: 'name',
          title: 'Producto',
          dataIndex: 'name',
          render: (_: any, record: any) => {
            return record.name;
          },
        },
        {
          key: 'quantity',
          title: 'Cantidad',
          dataIndex: 'quantity',
          render: (_: any, record: any) => {
            return (
              <InputNumber
                min={1}
                value={record.quantity}
                onChange={(value) =>
                  handleEditQuantityRow(record.id_chip, value ?? 1)
                }
              />
            );
          },
        },
        {
          key: 'actions',
          title: 'Acciones',
          render: (_: any, record: any) => {
            return (
              <Button
                type="primary"
                danger
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => handleRemoveRow(record.id_chip)}
              />
            );
          },
        },
      ]);
    }
  }, [priceVisible]);

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24} md={18}>
        <Select
          showSearch
          placeholder="Buscar producto"
          optionFilterProp="children"
          value={selectedValue}
          onSelect={handleSelect}
          filterOption={(input, option) =>
            `${option?.label ?? ''}`
              .toLocaleLowerCase()
              .includes(input?.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={maestro.map((chip) => ({
            label: chip.nombre,
            value: chip.id_chip,
          }))}
        />
      </Col>
      <Col xs={20} md={4}>
        <InputNumber
          min={1}
          value={quantity}
          onChange={(value) => setQuantity(value ?? 1)}
          style={{
            width: '100%',
          }}
        />
      </Col>
      <Col xs={4} md={2}>
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={handleAdd}
        />
      </Col>
      <Col span={24}>
        <Table
          scroll={{ x: 400 }}
          rowKey={(data) => data.id_chip}
          columns={columns}
          dataSource={chipsAdded}
        />
      </Col>
    </Row>
  );
};

export default FormMaestro;
