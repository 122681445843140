import { useEffect, useState } from 'react';
import { Row, Col, Card, Alert } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import FormGenerator from './components/FormGenerator';
import http from './libs/http';
import { API_LOAD_FORM, API_SAVE_FORM, getUrlLogo } from './const/api';
import LOGO_DEFAULT from './logo_dm.png';
import FormSection from './components/FormSection';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper/modules';
import SwiperType from 'swiper';

import 'swiper/css';

function App() {
  const [searchParams] = useSearchParams();
  const [logo, setLogo] = useState<string>(LOGO_DEFAULT);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [instance, setInstance] = useState<string>('');
  const [isLoadingSendData, setIsLoadingSendData] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [fields, setFields] = useState<any>(null);
  const [maestro, setMaestro] = useState<any[]>([]);
  const [metadata, setMetadata] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [currentSection, setCurrentSection] = useState<number>(0);
  const [dataToSend, setDataToSend] = useState<any[]>([]);
  const [swiper, setSwiper] = useState<SwiperType | null>(null);

  const onContinue = (values: any) => {
    setDataToSend([...dataToSend, ...values]);
    swiper?.slideNext();
  };

  const onComplete = async (newValues: any) => {
    const sectionsHasMaestro = data?.secciones?.filter((seccion: any) =>
      seccion?.campos?.find((campo: any) => campo.tipo === 'maestro')
    );
    const fieldsMaestro: string[] = [];
    for (const section of sectionsHasMaestro) {
      const campos: string[] = section?.campos
        ?.filter((campo: any) => campo.tipo === 'maestro')
        .map((campo: any) => campo.id_campo);
      campos.forEach((campo) => {
        fieldsMaestro.push(campo);
      });
    }

    const maestros = newValues
      .filter((value: any) => fieldsMaestro.includes(`${value.id_campo}`))
      .map((value: any) => ({ items: value.value }))
      .flatMap((item: any) => item.items);
    const fields = newValues.filter(
      (value: any) => !fieldsMaestro.includes(`${value.id_campo}`)
    );

    try {
      const values = [...dataToSend, ...fields].map(
        (d: {
          id_campo: string;
          id: number;
          value: any;
          send_email: boolean;
        }) => {
          const seccionData = data?.secciones?.find((seccion: any) =>
            seccion?.campos?.find((campo: any) => campo.id_campo === d.id_campo)
          );
          d.send_email = false;
          if (seccionData) {
            const campoData = seccionData?.campos?.find(
              (campo: any) => campo.id_campo === d.id_campo
            );
            d.send_email = campoData ? !!campoData?.envio_email : false;
          }
          if (typeof d.value === 'undefined') {
            d.value = '';
          }
          return d;
        }
      );

      const token = searchParams.get('token');
      const decoded = atob(token!);

      const dataParams = decoded!.split('|');
      setIsLoadingSendData(true);

      await http.post<{ success: boolean; data: string }>(API_SAVE_FORM, {
        customer: {
          phone: '123123123',
        },
        form: {
          FormId: parseInt(metadata.campana.formulario.id_formulario),
          Version: parseInt(metadata.campana.formulario.version),
          VisitId: parseInt(dataParams[1].replace('"', '')),
          SendCopyEmail: metadata.campana.envia_copia_punto,
          Instance: dataParams[0].replace('"', ''),
        },
        form_data: values,
        detalle_items: maestros,
      });
      setIsSuccess(true);
    } catch (e: any) {
      setError(
        `Se ha producido un error al guardar el formulario: ${e.message}`
      );
    } finally {
      setIsLoadingSendData(false);
    }
  };

  useEffect(() => {
    (async () => {
      setError('');

      const token = searchParams.get('token');

      if (!token) {
        setError('No se han encontrado parámetros de entrada');
        return;
      }
      const decoded = atob(token);
      const data = decoded.split('|');

      setIsLoading(true);
      setData([]);
      try {
        setInstance(data[0].replace('"', ''));
        const response = await http.post<{
          success: boolean;
          data: any;
          metadata: any;
          campos: string;
          maestro: string;
        }>(API_LOAD_FORM, {
          instance: data[0].replace('"', ''),
          id_visita: data[1].replace('"', ''),
        });
        const { data: dataResponse } = response;

        if (dataResponse.success) {
          const jsonData = dataResponse.data;
          setData(jsonData);
          setFields(JSON.parse(dataResponse.campos));
          setMaestro(JSON.parse(dataResponse.maestro));
          setMetadata(dataResponse.metadata);
        } else {
          setError(dataResponse.data);
        }
      } catch (e: any) {
        setError(
          `Se ha producido un error al cargar el formulario: ${e.message}`
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [searchParams]);

  useEffect(() => {
    if (instance) {
      setLogo(getUrlLogo(instance));
    }

    if (
      metadata &&
      metadata?.campana?.cliente?.logo &&
      !`${metadata?.campana?.cliente?.logo}`.includes('no-image')
    ) {
      setLogo(metadata?.campana?.cliente?.logo);
    }

    if (metadata && metadata?.campana?.cliente) {
      document.title = `${metadata?.campana?.cliente?.nombre}`;
    }
  }, [metadata, instance]);

  if (isSuccess) {
    return (
      <Row>
        <Col
          xs={{
            offset: 1,
            span: 22,
          }}
          md={{
            offset: 4,
            span: 16,
          }}
          lg={{
            offset: 7,
            span: 10,
          }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '20px',
          }}
        >
          <img src={logo} alt="Logo" width={120} />
        </Col>
        <Col
          xs={{
            offset: 1,
            span: 22,
          }}
          md={{
            offset: 4,
            span: 16,
          }}
          lg={{
            offset: 8,
            span: 8,
          }}
        >
          <Alert
            message={
              metadata?.campana?.wp_mensaje_fin_form ||
              'Hemos recibido tu solicitud, nos pondremos en contacto contigo'
            }
            type="success"
          />
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col
        xs={{
          offset: 1,
          span: 22,
        }}
        md={{
          offset: 4,
          span: 16,
        }}
        lg={{
          offset: 7,
          span: 10,
        }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <img src={logo} alt="Logo" width={120} />
      </Col>
      <Col
        xs={{
          offset: 1,
          span: 22,
        }}
        md={{
          offset: 4,
          span: 16,
        }}
        lg={{
          offset: 7,
          span: 10,
        }}
      >
        <Card
          bordered={false}
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
          }}
          bodyStyle={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {error && (
            <Alert
              message={error}
              type="error"
              closable
              onClose={() => {
                setError('');
              }}
            />
          )}
          {isLoading && (
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          )}
          {data?.secciones?.length > 0 && (
            <Row justify="center" gutter={16}>
              {data?.secciones?.map((section: any, index: number) => (
                <Col key={index}>
                  <div
                    style={{
                      border: '1px solid #ddd',
                      padding: '5px',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignContent: 'center',
                      backgroundColor:
                        index === currentSection ? '#1890ff' : '#fff',
                      color: index === currentSection ? '#fff' : '#000',
                    }}
                  >
                    {index + 1}
                  </div>
                </Col>
              ))}
            </Row>
          )}

          {data?.secciones?.length > 0 && (
            <Swiper
              modules={[Controller]}
              onSwiper={setSwiper}
              controller={{ control: swiper }}
              slidesPerView={1}
              noSwiping={true}
              style={{
                width: '100%',
              }}
              onSlideChange={(sw: SwiperType) => {
                setCurrentSection(sw.activeIndex);
              }}
              initialSlide={currentSection}
              allowTouchMove={false}
            >
              {data?.secciones?.map((seccion: any, index: number) => {
                return (
                  <SwiperSlide key={index}>
                    <FormSection title={seccion.titulo}>
                      <FormGenerator
                        index={index}
                        data={seccion.campos}
                        fields={fields}
                        maestro={maestro}
                        isComplete={index === data?.secciones?.length - 1}
                        onComplete={onComplete}
                        onContinue={onContinue}
                        onBack={() => swiper?.slidePrev()}
                        loading={isLoadingSendData}
                      />
                    </FormSection>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default App;
