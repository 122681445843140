import { Row, Col } from 'antd';
import { FC } from 'react';

type Props = {
  children: React.ReactNode;
  title: string;
};

const FormSection: FC<Props> = ({ children, title }) => {
  return (
    <Row style={{ width: '100%' }}>
      <Col
        xs={24}
        style={{ fontWeight: 600, fontSize: '32px', textAlign: 'center' }}
      >
        {title}
      </Col>
      <Col xs={24}>{children}</Col>
    </Row>
  );
};

export default FormSection;
